.colabTraits {
    margin-top: 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background:#47317D;;
    background-repeat: round;
    // background-size: 100%;
    text-align: center;
    border-radius: 10px;
    color: white;
        canvas {
        color: white;
        outline: none;
      }
  
  }
  
  
  .traitsWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    width: 100%;
    color: black;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  .traits-container {
    margin-top: 2.5%;
    position: relative;
    width: 60vw;
    height: 60vw;
    text-align: center;
    background-color:white;
    max-width: 100%;
    max-height: 100%;
    margin-bottom: 10px;
    border-radius: 10px;
    
   
  
  
  
  }
  
  @media (max-width: 768px)
  {
  .traits-container {
      width: 20rem;
      height: 20rem;
  }
  .traits-container .labels{
    right: -90%;
  
   
  }
  
  }

  
  .mentor {
    z-index: 23;
    position: absolute;
    transform: rotate(-37deg);
    color: #f1c40f;
    top:10.3%;
    left:11%;;
  }
  .empath {
    z-index: 23;
    transform: rotate(109deg);
    position: absolute;
    color: #27ae60;
    top: 61%;
    left:85%;
  }
  .leader {
    z-index: 23;
    transform: rotate(38deg);
    position: absolute;
    color: #3498db;
    top: 10.3%;
    left: 70%;
  }
  .entrepreneurial {
    z-index: 23;
    transform: rotate(-106deg);
    position: absolute;
    color: #8e44ad;
    top: 61%;
    left: -11%;
  }
  .risk-taker {
    z-index: 23;
   
    position: absolute;
    color: #e74c3c;
    top: 95%;
    left: 38.5%;
    transform: rotate(1deg);
  }
  
  .labels{
    
    font-size: 2vw;
    font-weight: bold;
  }
  
  .footer-items{
    display: flex;
    flex-direction: column;
    align-items: center;
    Button{
      margin-bottom:10px;
    }
    Button:hover{
      background-color: #6A44B6;
      color: white;
    }
  
  }