@media (min-width: 768px) {
.missionStatement {
    margin-top: 3%;
  
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #47317D;;
    background-repeat: round;
    border-radius: 10px;
    // background-size: 100%;
  }
  .missionSection {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    color: white;
    font-size: 15px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    width: 100%;
    canvas {
      color: white;
      outline: none;
    }
  }
  .radarDiv {
    
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding-left: 5%;
    border-bottom-left-radius: 10px;
    color: white;
    canvas {
      color: white;
      outline: none;
    }
  }
  
  .missionList {
    display: flex;
    flex-direction: column;
    font-size: 1.1vw;
    background-color: white;
    color: black;
    padding-left: 3%;
    padding-right: 3%;
    width: 40%;
    border-bottom-right-radius: 10px;
  }
  .title {
    font-size: 3.7vw;
    span {
      padding: 5px;
    }
  }
}
@media (max-width: 768px) {
  .missionStatement {
      margin-top: 10%;
    
      color: white;
      display: flex;
      flex-direction: column;
      align-items: center;
      background:#47317D;
      background-repeat: round;
      border-radius: 2%;
      // background-size: 100%;
    }
    .missionSection {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      align-items: center;
      background-color: white;
      color: white;
      font-size: 15px;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      width: 100%;
      canvas {
        color: white;
        outline: none;
      }
    }
    .radarDiv {
      
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
    
      
      color: white;
      canvas {
        color: white;
        outline: none;
      }
    }
    
    .missionList {
      display: flex;
      flex-direction: column;
      font-size: 1.7vw;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      background-color: white;
      color: black;
      padding-left: 3%;
      padding-right: 3%;
      width: 100%;
      margin-top: 3%;
      p{
        text-align: left;
      }
    }
    .title {
      font-size: 3.7vw;
      span {
        padding: 5px;
      }
    }
  }
  .mentor {
  z-index: 23;
  position: absolute;
  transform: rotate(-106deg);
  color: #f1c40f;
  top: 60%;
  left: 11%;
}
.empath {
  z-index: 23;
  transform: rotate(-36deg);
  position: absolute;
  color: #27ae60;
  top: 18.3%;
  left:24%;
}
.leader {
  z-index: 23;
  transform: rotate(37deg);
  position: absolute;
  color: #3498db;
  top: 18.3%;
  left: 72%;
}
.entrepreneurial {
  z-index: 23;
  transform: rotate(109deg);
  position: absolute;
  color: #8e44ad;
  top: 62.3%;
  left:78%;
}
.risk-taker {
  z-index: 23;
 
  position: absolute;
  color: #e74c3c;
  top: 88%;
  left: 44.5%;
  transform: rotate(1deg);
}

.labels{
  
  font-size: 2vw;
  font-weight: bold;
}